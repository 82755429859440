@import '~styles/mixins';

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 10px 16px;
  background: var(--catalog-card-color);

  &:nth-child(1) {
    border-radius: 12px 12px 0px 0px;
  }
  &:nth-last-child(1) {
    border-radius: 0px 0px 12px 12px;
  }
  &:only-child {
    border-radius: 12px;
  }

  &__userInfo {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  &__avatarWrapper {
    width: 40px;
    height: 40px;
    border-radius: 200px;
    overflow: hidden;
  }
  &__avatar {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &__content {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
  }
}
