@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/Montserrat-Light.ttf') format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src:
    url('../assets/fonts/Montserrat-Regular.woff2') format('woff2'),
    url('../assets/fonts/Montserrat-Regular.woff') format('woff'),
    url('../assets/fonts/Montserrat-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src:
    url('../assets/fonts/Montserrat-Medium.woff2') format('woff2'),
    url('../assets/fonts/Montserrat-Medium.woff') format('woff'),
    url('../assets/fonts/Montserrat-Medium.ttf') format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src:
    url('../assets/fonts/Montserrat-SemiBold.woff2') format('woff2'),
    url('../assets/fonts/Montserrat-SemiBold.woff') format('woff'),
    url('../assets/fonts/Montserrat-SemiBold.ttf') format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src:
    url('../assets/fonts/Montserrat-Bold.woff2') format('woff2'),
    url('../assets/fonts/Montserrat-Bold.woff') format('woff'),
    url('../assets/fonts/Montserrat-Bold.ttf') format('opentype');
}
