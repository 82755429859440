@import '~styles/mixins';

.form {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  min-height: 82px;
  max-height: fit-content;
  padding: 10px 20px;
  background-color: color(var(--background-primary));
  border: 1px solid color(var(--color-primary), 0.2);
  border-radius: 30px;

  &__attach {
    width: 40px;
    height: 40px;
    padding: 0;
  }

  &__textarea {
    flex: 1;
    min-height: 20px;
    height: 20px;
    max-height: 100px;
    font-weight: 400;
    font-size: 16px;
    background-color: transparent;
    border: none;
    outline: none;
    resize: none;

    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  &__send {
    width: 50px;
    height: 50px;
    padding: 0;
    color: rgba(255, 255, 255, 1);
    border-radius: 50%;
    rotate: 90deg;
  }
}
