@import '~styles/mixins';

.audio {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 320px;
  width: 100%;
  background: color(var(--background-secondary));
  border-radius: 10px 10px 10px 4px;

  .header {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: auto;
    column-gap: 12px;
    row-gap: 4px;
  }

  .button {
    grid-row: span 2;
    width: 50px;
    height: 50px;
    background: color(var(--wave-color));
    border-radius: 200px;
  }

  .waveform {
    color: color(var(--wave-color));
    cursor: pointer;
  }

  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    font-size: 12px;
  }

  .created {
    align-self: flex-end;
    font-weight: 300;
    font-size: 10px;
    color: color(var(--color-date));
  }
}
