@import '~styles/mixins';

.subscribe {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 780px;
    width: 100%;
    height: max-content;
    padding: 60px 40px;
    border-radius: 30px;
    gap: 40px;
    text-align: center;
    background: color(var(--background-primary), 0.9);
  }

  &__imageWrapper {
    width: 138px;
    height: 138px;
    border-radius: 50%;
    overflow: hidden;
  }

  &__name {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }

  &__description {
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }

  &__rates {
    display: flex;
    max-width: 480px;
    width: 100%;
    height: 40px;
    border: 1px solid color(var(--color-purple));
    border-radius: 12px;
    overflow: hidden;

    .rate {
      max-width: 160px;
      width: 100%;
      height: 100%;
      padding: 0;
      font-size: 16px;
      font-weight: 500;
      line-height: 19.5px;
      border-radius: 0;

      &:first-child {
        border-radius: 12px 0 0 12px;
        border-right: 0px !important;
      }
      &:last-child {
        border-radius: 0 12px 12px 0;
        border-left: 0px !important;
      }

      &.border {
        border: 1px solid color(var(--color-purple));
      }
    }
  }

  &__button {
    max-width: 300px;
    width: 100%;
    padding: 0 14px;
  }
}
