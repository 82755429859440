@import '~styles/mixins';

.chats {
  height: 100%;

  .container {
    display: flex;
    gap: 20px;
    max-width: 1840px;
    width: 100%;
    padding-inline: 5px;
  }

  &__right {
    flex: 1;
  }
  .wrapper {
    display: flex;
    height: calc(100% - 82px);
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0;
    }

    .messages {
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: 100%;
      height: max-content;
      padding: 40px 30px;

      @media (max-width: 1200px) {
        padding: 30px 20px;
      }

      @media (max-width: 700px) {
        padding: 20px 10px;
      }

      .message {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        gap: 4px;
        max-width: 520px;
        min-width: 100px;
        width: max-content;
        padding: 14px;
        padding-bottom: 8px;
        background: color(var(--background-secondary));

        &.isSender {
          align-self: flex-end;
          border-radius: 10px 10px 4px 10px;
        }

        &.isReceiver {
          align-self: flex-start;
          border-radius: 10px 10px 10px 4px;
        }

        &__text {
          font-weight: 400;
          line-height: 19.5px;
        }

        &__time {
          align-self: flex-end;
          font-weight: 300;
          font-size: 10px;
          line-height: 12.19px;
          color: color(var(--color-date));
        }
      }
    }

    .scrollButton {
      position: absolute;
      right: 20px;
      bottom: 102px;
      width: 50px;
      height: 50px;
      padding: 0;
      border-radius: 50%;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.3s;

      &.scrollActive {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
}
