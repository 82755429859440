@import '~styles/mixins';

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;

  &__input {
    width: 100%;
    height: 50px;
    padding: 0 20px;
    color: inherit;
    font-size: 14px;
    line-height: 17.07px;
    background-color: color(var(--background-input));
    border: 1px solid color(var(--color-primary), 0.2);
    border-radius: 30px;
    outline: none;

    &::placeholder {
      color: color(var(--color-primary), 0.6);
    }
  }

  &__textarea {
    width: 100%;
    height: 160px;
    padding: 16px 20px;
    font-family: inherit;
    resize: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__forgotPassword {
    width: max-content;
    color: color(var(--color-purple));
    font-size: 14px;
    line-height: 17.07px;
  }

  &__submit {
    max-width: 300px;
    width: 100%;
    font-size: 16px;
  }

  &__file {
    position: relative;
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    height: 80px;
    padding: 0px 20px;
    color: color(var(--color-primary), 0.6);
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    border: 2px dashed color(var(--color-primary), 0.2);
    border-radius: 10px;

    & > input {
      position: absolute;
      inset: 0;
      display: none;
    }
  }

  &__redirect {
    font-weight: 400;
  }

  &__link {
    color: color(var(--color-purple));
  }

  .error {
    color: red;
    align-self: flex-start;
    font-size: 14px;
    font-weight: 600;
  }
}
