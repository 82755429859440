@import '~styles/mixins';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  height: 48px;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  background: color(var(--background-secondary));
  border-radius: 200px;

  &.filled {
    background: color(var(--color-purple));
    border: 1px solid color(var(--color-primary), 0.2);
    color: #fff;
  }

  &.transparent {
    background: transparent;
  }

  &.red {
    background: color(var(--color-red));
    color: #fff;
  }
}
