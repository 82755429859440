@import '~styles/mixins';

.setting {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 19.5px;
    color: color(var(--color-primary), 0.7);
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      height: 48px;
      padding: 0 10px;
      background-color: color(var(--color-primary-inverse), 0.2);
      cursor: pointer;

      &:first-child {
        border-radius: 12px 12px 0px 0px;
      }

      &:last-child {
        border-radius: 0px 0px 12px 12px;
      }

      &:only-child {
        border-radius: 12px;
      }

      &:not(:first-child) {
        .item__title {
          &::first-letter {
            text-transform: uppercase;
          }
        }
      }

      &__left {
        display: flex;
        align-items: center;
        gap: 15px;
        opacity: 0.7;
      }

      &__title {
        font-weight: 500;
        font-size: 14px;
        line-height: 17.07px;
      }

      &__right {
        display: flex;
        align-items: center;
        gap: 10px;
        color: color(var(--color-primary), 0.7);
        opacity: 0.4;
      }

      &__caption {
        font-size: 12px;
        line-height: 14.63px;

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }
}
