@import '~styles/mixins';

.edit {
  display: grid;
  gap: 12px;
  width: 100%;

  &__saveButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    height: 48px;
    padding: 0 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    transition: all 0.2s linear;
    border-radius: 200px;

    &_active {
      background: color(var(--color-purple));
      border: 1px solid color(var(--color-primary), 0.2);
      color: #fff;
      cursor: pointer;
    }

    &_disabled {
      border: 1px solid color(var(--color-purple));
      background: color(var(--color-primary), 0.1);
      color: black;
    }
  }

  &__title {
    color: color(var(--color-primary), 0.7);
    font-weight: 600;
    font-size: 16px;
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 4px;

    .item {
      display: flex;
      align-items: center;
      gap: 15px;
      height: 59px;
      padding-inline: 10px;
      background-color: var(--catalog-card-color);

      &:nth-child(1) {
        border-radius: 12px 12px 0 0;
      }

      &:nth-last-child(1) {
        border-radius: 0 0 12px 12px;
      }

      &__title {
        width: 80px;
        color: color(var(--color-primary), 0.7);
        font-size: 14px;
      }

      &__label {
        display: flex;
        align-items: center;
        gap: 5px;
        width: 120px;
        height: 29px;
        padding-inline: 16px;
        background-color: color(var(--color-primary-inverse), 0.2);
        border-radius: 8px;
      }
      &__input {
        width: 100%;
        color: var(--text-primary);
        font-size: 14px;
        font-weight: 500;
        background-color: transparent;
        border: none;
        outline: none;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          appearance: none;
          margin: 0;
        }
      }

      &__currency {
        font-weight: 500;
        font-size: 12px;
        color: color(var(--color-primary), 0.7);
      }
    }
  }
}
