@import '~styles/mixins';

.login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    max-width: 520px;
    width: 100%;
    padding: 40px;
    border-radius: 30px;
    background: color(var(--background-input), 0.9);
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }
}
