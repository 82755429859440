@import 'mixins';
@import 'variables';

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  font-size: var(--font-size);
}

body {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  background-color: color(var(--background-primary));
  color: color(var(--color-primary));
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  overflow-x: hidden;
  width: 100vw;
  max-width: 100vw;
  min-width: 100vw;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  user-select: none;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  cursor: pointer;
  color: inherit;
  font-family: inherit;
  background-color: transparent;
  border: none;
}

img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#root {
  width: 100%;
  height: 100%;
}

.layout {
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-image: url('../../public/assets/background.svg'),
    linear-gradient(
      152.59deg,
      rgb(255, 255, 255) 5.746%,
      rgb(148, 97, 233) 137.017%
    );
  background-repeat: repeat;
  background-position: left;
  overflow: hidden;

  &.dark {
    background-image: url('../../public/assets/backgroundBlack.svg'),
      linear-gradient(
        152.59deg,
        rgb(22, 8, 35) 5.746%,
        rgb(60, 19, 76) 137.017%
      );
    background-repeat: repeat;
    background-position: left;
  }

  &.layoutScroll{
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    max-height: 100%;
    min-height: 100vh;

    #main{
      height: 100%;
    }
  }
}

#main {
  width: 100%;
  height: calc(100vh - 80px);
  padding-inline: 10px;
  padding-bottom: 20px;
}

.container {
  position: relative;
  max-width: 1000px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.link {
  position: absolute;
  inset: 0;
  cursor: pointer;
}
