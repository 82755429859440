@import '~styles/mixins';

.actions {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: -10px;

  .button {
    height: 40px;
    padding: 0 30px;
  }

  .link {
    color: color(var(--color-purple));
    font-weight: 400;
    line-height: 24px;
  }
}
