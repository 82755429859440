@import '~styles/mixins';

.referral {
  display: grid;
  gap: 40px;
  width: 100%;

  .title {
    font-weight: 600;
    font-size: 16px;
    color: color(var(--color-primary), 0.7);
  }

  &__header {
    display: grid;
    gap: 12px;
  }

  &__link {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    gap: 12px;
    width: 100%;
    height: 67px;
    padding: 0px 16px;
    background-color: var(--catalog-card-color);
    border-radius: 12px;
  }

  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 17.07px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__button {
    width: 96px;
    height: 37px;
    border-radius: 200px;
    font-size: 14px;
  }

  &__invited {
    display: grid;
    gap: 12px;
  }

  .cards {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      height: 48px;
      padding: 10px;
      opacity: 0.7;
      background-color: color(var(--color-primary-inverse), 0.2);

      &:only-child {
        border-radius: 12px !important;
      }

      &:nth-child(1) {
        border-radius: 12px 12px 0 0;
      }

      &:nth-last-child(1) {
        border-radius: 0 0 12px 12px;
      }

      &__name {
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
}
