@import '~styles/mixins';

.item {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 10px;
  padding: 10px 20px;
  background: var(--panel-item-bg);

  &_link {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &.active {
    border-right: 4px solid color(var(--color-purple));
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__avatar {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 200px;
  }

  &__name {
    font-size: 14px;
    line-height: 17px;
  }

  &__desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
