@import '~styles/mixins';

.catalog {
  height: 100%;

  .content {
    width: 100%;
    padding: 40px;
    border-radius: 12px;
    background: color(var(--background-primary), 0.9);
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    padding: 0 15px;
  }
  &__inputWrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px;
    border-radius: 200px;
    background: color(var(--color-primary-inverse), 0.2);
    cursor: text;
  }
  &__input {
    width: 90%;
    border: none;
    background: transparent;
    outline: none;
  }
  &__searchBtn {
    width: 50px;
    height: 50px;
  }

  .cards {
    display: flex;
    flex-direction: column;
    gap: 4px;
    height: calc(100% - 90px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
