@import '~styles/mixins';

.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
}

.content {
  width: 100%;
}
